import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import { reducer as formReducer } from 'redux-form';
import userReducer from './userReducer';
import articleReducer from './articleReducer';

export default combineReducers({
   errors: errorReducer,
   auth: authReducer,
   form: formReducer,
   userStore: userReducer,
   articleStore: articleReducer
});