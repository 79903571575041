import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';
import MyCreatableSelect from '../ui/my-creatable-select';

import DayPickerInput from 'react-day-picker/DayPickerInput';

export const SaleForm = ({ values,
    handleSubmit,
    handleCancel,
    setFieldValue,
    formErrors,
    users,
    polworkareas,
    tecworkareas,
    ogs,
    interventions,
    articles }) => {        

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row">
                <label htmlFor="date" className="col-sm-1 col-form-label">Datum</label>
                <div className="col-sm-5">
                    <DayPickerInput
                        id="date"
                        name="date"
                        value={values.transaction_monetary.date}
                        onDayChange={day => setFieldValue('date', day)}
                        inputProps={{ className: "form-control" }} />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.date}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="seller" className="col-sm-1 col-form-label">verkauft von</label>
                <div className="col-sm-5">
                    <MySelect
                        id="seller"
                        name="seller"
                        value={values.seller}
                        options={users}
                        onChange={setFieldValue}
                        onChangeUpdate={[{ key: 'og' , field: 'og' }]}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.seller}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="og" className="col-sm-1 col-form-label">OG</label>
                <div className="col-sm-5">
                    <MySelect
                        id="og"
                        name="og"
                        value={values.og}
                        options={ogs}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.og}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="transaction_stock.article" className="col-sm-1 col-form-label">Artikel</label>
                <div className="col-sm-5">
                    <MySelect
                        id="transaction_stock.article"
                        name="transaction_stock.article"
                        value={values.transaction_stock.article}
                        options={articles}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.article}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="transaction_stock.quantity" className="col-sm-1 col-form-label">Menge</label>
                <div className="col-sm-5">
                    <Field type="number" min="0" value={values.transaction_stock.quantity} name="transaction_stock.quantity" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.quantity}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="transaction_monetary.quantity" className="col-sm-1 col-form-label">Geld</label>
                <div className="col-sm-5">
                    <Field type="number" step="0.01" min="0" value={values.transaction_monetary.quantity} name="transaction_monetary.quantity" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.money}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="intervention" className="col-sm-1 col-form-label">Intervention</label>
                <div className="col-sm-5">
                    <MySelect
                        id="intervention"
                        name="intervention"
                        value={values.intervention}
                        options={interventions}
                        onChange={setFieldValue}
                        onChangeUpdate={[{ key: 'polworkarea' , field: 'polworkarea' },{ key: 'tecworkarea' , field: 'tecworkarea' }]}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.intervention}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="polworkarea" className="col-sm-1 col-form-label">AF (pol.)</label>
                <div className="col-sm-5">
                    <MySelect
                        id="polworkarea"
                        name="polworkarea"
                        value={values.polworkarea}
                        options={polworkareas}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.polworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="tecworkarea" className="col-sm-1 col-form-label">AF (tech.)</label>
                <div className="col-sm-5">
                    <MySelect
                        id="tecworkarea"
                        name="tecworkarea"
                        value={values.tecworkarea}
                        options={tecworkareas}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.tecworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="comment" className="col-sm-1 col-form-label">Kommentar</label>
                <div className="col-sm-5">
                    <Field type="textarea" name="comment" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.comment}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="paid" className="col-sm-1 col-form-label">bezahlt</label>
                <div className="col-sm-5">
                    <Field type="checkbox" name="paid" className="form-control" checked={values.paid} />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
                <div className="col-sm-5">
                    {typeof formErrors.message === 'string' && <span className="form-error">{formErrors.message}</span>}
                </div>
            </div>
        </form>
    )
}