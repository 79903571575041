import React, { Component } from 'react';
import { fetch, postCashBalance, fetchLoggedInUser, Endpoints, updatePassword, getUserBalance, getUserCashBalance } from '../../helpers/api'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { ChangePwForm } from './change-pw-form';
import { apiDataToFormData } from '../../helpers/functions';
import { CashBalanceForm } from '../balance/cash-balance-form';
import { OpenCashBalance } from '../balance/open-cash-balance';

import * as Yup from 'yup';

class Profile extends Component {
    constructor() {
        super();

        this.state = {
            user: {},
            userinfo: { balance: 'lade..' },
            settleTransaction: {},
            changepw: false,
            ferrors: {},
            users: []
        }

        this.handleChangePw = this.handleChangePw.bind(this);
        this.handleCancelPw = this.handleCancelPw.bind(this);
        this.handleCancelSettle = this.handleCancelSettle.bind(this);
        this.handleSettle = this.handleSettle.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetchLoggedInUser().then((response) => {
            console.log(response.data);
            this.setState({ user: response.data });

            getUserCashBalance(response.data).then((response) => {
                this.setState({ userinfo: { balance: response.data.balance }});
            })
        })

        fetch(Endpoints.USER).then((response) => {
            this.setState({ users: response.data.users })
        });        
    }

    handleCancelPw() {
        this.setState({ changepw: false })
        this.setErrors( {} );
    }

    handleCancelSettle() {
        this.setState({ settleTransaction: {} })
    }

    handleChangePw() {        
        this.setState({ changepw: true })
    }

    handleSubmit(values) {
        updatePassword(this.state.user, {
            pwalt: values.pwold,
            pwneu: values.pwnew,
            pwneu2: values.pwnew2
        }).then(() => {
            this.setState( {changepw: false });
            this.setErrors( {} );
            window.alert("Passwort geändert")
        }).catch(error => {
            const response = error.response;
            this.setErrors(response.data);
        })
    }

    setErrors(errors) {
        this.setState({ ferrors: errors });
    }

    handleSettle(user) {
        this.setState({ settleTransaction: { id: {}, userdebit: user } });
    }

    handleSave(values) {
        postCashBalance(values).then((response) => {
            this.initValues();
            this.handleCancelSettle();
        }).catch(() => {
            console.log('something went wrong');
        })

    }

    render() {
        const { user, users, userinfo, changepw, ferrors, settleTransaction } = this.state;

        const formUsers = apiDataToFormData(users, 'id', 'handle');

        const changePwButton = (
            <button className="btn" onClick={this.handleChangePw}>
                <i className="fa fa-plus" />
                Passwort ändern
            </button>
        )

        const changePwForm = (
            <Formik
                initialValues={{
                    pwold: '',
                    pwnew: '',
                    pwnew2: ''
                }}
                validationSchema={Yup.object().shape({
                    pwnew: Yup.string()
                        .min(6, 'Passwort muss mindestens 6 Zeichen lang sein'),
                    pwnew2: Yup.string()
                        .oneOf([Yup.ref('pwnew'), null], 'Passwörter unterschiedlich')
                })}
                onSubmit={(values) => this.handleSubmit(values)}
                render={({ errors, status, touched }) => (
                    <Form autoComplete="off">
                        <div className="form-group row">
                            <label htmlFor="pwold" className="col-sm-1 col-form-label">PW alt</label>
                            <div className="col-sm-5">
                                <Field type="password" name="pwold" className="form-control" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="pwnew" className="col-sm-1 col-form-label">PW neu</label>
                            <div className="col-sm-5">
                                <Field type="password" name="pwnew" className={'form-control' + (errors.pwnew && touched.pwnew ? ' is-invalid' : '')} />
                            </div>
                            <ErrorMessage name="pwnew" component="span" className="form-error" />
                        </div>

                        <div className="form-group row">
                            <label htmlFor="pwnew2" className="col-sm-1 col-form-label">PW neu (best.)</label>
                            <div className="col-sm-5">
                                <Field type="password" name="pwnew2" className={'form-control' + (errors.pwnew2 && touched.pwnew2 ? ' is-invalid' : '')} />
                            </div>
                            <ErrorMessage name="pwnew2" component="span" className="form-error" />
                        </div>

                        <div className="form-group row">
                            <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                            <div className="col-sm-4">
                                <button type="submit" className="btn btn-primary btn-block">submit</button>
                            </div>
                            <div className="col-sm-1">
                                <button onClick={this.handleCancelPw} className="btn btn-block">cancel</button>
                            </div>
                            <div className="col-sm-5">
                                {typeof ferrors.message === 'string' && <span className="form-error">{ferrors.message}</span>}
                            </div>
                        </div>
                    </Form>
                )
                }
            />
        )

        const form = (
            <Formik
                initialValues={settleTransaction}
                onSubmit={(values) => this.handleSave(values)}
                render={
                    props => <CashBalanceForm
                        users={formUsers}
                        formErrors={{}}
                        handleCancel={this.handleCancelSettle}
                        {...props} />
                }
                enableReinitialize={true}
            />
        )

        const userinfoTable = (
            <div>
                <table className="pure-table pure-table-bordered" style={{ margin: 10 }}>
                    <tbody>
                        <tr>
                            <td><b>Kürzel</b></td>
                            <td>{user.handle}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><b>Mail</b></td>
                            <td>{user.email}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><b>Kassastand</b></td>
                            <td>{userinfo.balance}</td>
                            <td>
                            <button className="btn" onClick={e => this.handleSettle(user, e)}><i className="fa fa-edit" /> abrechnen</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )


        return (
            <div>
                {!settleTransaction.id && !changepw && userinfoTable}
                {!settleTransaction.id && (changepw ? changePwForm : changePwButton)}
                {settleTransaction.id && form}
                <br />
                <OpenCashBalance refresh={settleTransaction} />
            </div>

        );
    }
}

export default (Profile);