import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

export const CashBalanceForm = ({
    values,
    handleSubmit,
    handleCancel,
    setFieldValue,
    formErrors,
    users }) => {

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row">
                <label className="col-sm-1 col-form-label">von</label>
                <div className="col-sm-5">
                    <p>{values.userdebit.handle}</p>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="usercredit" className="col-sm-1 col-form-label">An wen</label>
                <div className="col-sm-5">
                    <MySelect
                        id="usercredit"
                        name="usercredit"
                        //value={values.polworkarea}
                        options={users}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.polworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="quantity" className="col-sm-1 col-form-label">Geld</label>
                <div className="col-sm-5">
                    <Field type="number" step="0.01" min="0" name="quantity" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.money}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
                <div className="col-sm-5">
                    {typeof formErrors.message === 'string' && <span className="form-error">{formErrors.message}</span>}
                </div>
            </div>
        </form >
    )
}