import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MyMultiSelect from '../ui/my-multi-select';

export const BalanceForm = ({
    values,
    handleSubmit,
    handleCancel,
    setFieldValue,
    formErrors,
    ogs,
    articles,
    articleCategories }) => {

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row">
                <label htmlFor="selectedOgs" className="col-sm-1 col-form-label">OGs</label>
                <div className="col-sm-5">
                    <MyMultiSelect
                        id="selectedOgs"
                        name="selectedOgs"
                        isMulti
                        //value={values.polworkarea}
                        options={ogs}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.polworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="selectedArticles" className="col-sm-1 col-form-label">Artikel</label>
                <div className="col-sm-5">
                    <MyMultiSelect
                        id="selectedArticles"
                        name="selectedArticles"
                        isMulti
                        //value={values.tecworkarea}
                        options={articles}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.tecworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="selectedCategories" className="col-sm-1 col-form-label">Kategorien</label>
                <div className="col-sm-5">
                    <MyMultiSelect
                        id="selectedCategories"
                        name="selectedCategories"
                        isMulti
                        //value={values.polworkarea}
                        options={articleCategories}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.polworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="date" className="col-sm-1 col-form-label">Datum von</label>
                <div className="col-sm-5">
                    <DayPickerInput
                        id="dateFrom"
                        name="dateFrom"
                        value={values.date}
                        onDayChange={day => setFieldValue('dateFrom', day)}
                        inputProps={{ className: "form-control" }} />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.date}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="date" className="col-sm-1 col-form-label">Datum bis</label>
                <div className="col-sm-5">
                    <DayPickerInput
                        id="dateTo"
                        name="dateTo"
                        value={values.date}
                        onDayChange={day => setFieldValue('dateTo', day)}
                        inputProps={{ className: "form-control" }} />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.date}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block" type="button">cancel</button>
                </div>
                <div className="col-sm-5">
                    {typeof formErrors.message === 'string' && <span className="form-error">{formErrors.message}</span>}
                </div>
            </div>
        </form>
    )
}