import React, { Component } from 'react';

import { fetchUsers, saveUser, fetchOgs } from '../../helpers/api';
import { Formik } from 'formik';
import { CrudTable } from '../ui/crud-table';
import { UserForm } from './user-form';

class User extends Component {
    constructor() {
        super();

        this.state = {
            users: [],
            ogs: [],
            selectedUser: {},
            errors: {}
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetchUsers().then((response) => {
            this.setState({ users: response.data.users });
        })
        fetchOgs().then((response) => {
            this.setState({ ogs: response.data.ogs });
        })
    }

    componentWillUnmount() {
        //this.props.clearUsers();
    }

    setSelectedUser(user) {
        this.setState({ selectedUser: { ...user } });
    }

    setErrors(errors) {
        this.setState({ errors: errors });
    }

    handleNew() {
        this.setSelectedUser({ id: {}, handle: "", email: "", og: {} });
    }

    handleEdit(row) {
        this.setSelectedUser(row.original);
    }

    handleCancel() {
        this.clearValues();
    }

    clearValues() {
        this.setSelectedUser({});
        this.setErrors({});
    }

    handleSave(values) {
        const og = values.og.id ? values.og : this.state.ogs.find(x => x.id === values.og.value);

        saveUser({
            ...this.state.selectedUser,
            handle: values.handle && values.handle.length !== 0 ? values.handle : undefined,
            email: values.email && values.email.length !== 0 ? values.email : undefined,
            og: og
        }).then(() => {
            this.initValues();
            this.clearValues();
        }).catch(error => {
            const response = error.response;
            this.setErrors(response.data);
        })
    }

    render() {
        const { selectedUser, users, ogs, errors } = this.state;

        const heading = <h2>User</h2>
        const loading = <div>loading...</div>
        const addUserButton = (
            <button className="btn" onClick={this.handleNew}>
                <i className="fa fa-plus" />
                hinzufügen
            </button>
        )

        const formOgs = ogs.map(og => ({ value: og.id, label: og.name }));

        const form = (
            <Formik
                initialValues={selectedUser}
                onSubmit={(values) => this.handleSave(values)}
                render={
                    props => <UserForm
                        ogs={formOgs}
                        formErrors={errors}
                        handleCancel={this.handleCancel}
                        {...props} />
                }
                enableReinitialize={true}
            />
        )

        const content = (
            <div>
                <CrudTable
                    data={this.state.users}
                    handleEdit={this.handleEdit}
                    columns={[
                        {
                            Header: "Kürzel/Name",
                            accessor: "handle",
                            maxWidth: 250
                        },
                        {
                            Header: "Email",
                            accessor: "email",
                            maxWidth: 350
                        },
                        {
                            Header: "OG",
                            accessor: "og.name",
                            maxWidth: 850
                        }
                    ]}
                />
            </div>
        )

        return (
            <div>
                {heading}
                {users.length > 0 ? content : loading}
                {(selectedUser.id && ogs.length > 0) ? form : addUserButton}
            </div >
        );
    }
}

export default (User);

