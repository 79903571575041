import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

export const OgForm = ({ values, handleSubmit, handleCancel, setFieldValue, users, accounts }) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group row">
                <label htmlFor="name" className="col-sm-1 col-form-label">Name</label>
                <div className="col-sm-5">
                    <Field type="text" name="name" className="form-control" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="salesmgr" className="col-sm-1 col-form-label">Vertriebler</label>
                <div className="col-sm-5">
                    <MySelect
                        id="salesmgr"
                        value={values.salesmgr}
                        name="salesmgr"
                        options={users}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="warehouse" className="col-sm-1 col-form-label">Lager</label>
                <div className="col-sm-5">
                    <MySelect
                        id="warehouse"
                        value={values.warehouse}
                        name="warehouse"
                        options={accounts}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
            </div>
        </form>
    )
}