import React, { Component } from 'react';
import Select from 'react-select';

class MySelect extends Component {
    handleChange = value => {
        this.props.onChange(this.props.name, value);

        let changes = this.props.onChangeUpdate;
        if (changes) {
            for (let i = 0; i < changes.length; i++) {
                this.props.onChange(changes[i]['key'], value[changes[i]['field']]);
            }
        }        
    }

    render() {        
        const selected = this.props.value && this.props.options.find(x => x.value === this.props.value.id);
        
        return (
            <Select
                options={this.props.options}
                onChange={this.handleChange}
                value={selected}
            />
        )
    }
}

export default (MySelect);