import axios from 'axios';
import { AUTH_USER, AUTH_ERROR }  from './types';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode';


export const loginUser = (history, user) => dispatch => {
    axios.post('/login', user)
    .then(res => {
        localStorage.setItem('token', res.data.access_token);        
        dispatch(setCurrentUser(res.data.access_token));
        history.push('/home');
    })
    .catch(err => {
        console.log(err);
        dispatch({
            type: AUTH_ERROR,
            payload: err.response.data
        });
    });
}

export const setCurrentUser = token => {
    setAuthToken(token !== {} ? token : false);
    return {
        type: AUTH_USER,
        payload: token
    }
}

export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('token');
    dispatch(setCurrentUser({}));
    history.push('/login');
}