import React, { Component } from 'react';
import { Endpoints, fetch } from '../../helpers/api';
import ReactTable from "react-table";
import { filterInsensitive } from '../../helpers/functions';

export class SettledCashBalance extends Component {
    constructor() {
        super();

        this.state = {
            settledTransactions: []
        }
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetch(Endpoints.SETTLEDCASHBALANCE).then((response) => {
            this.setState({ settledTransactions: response.data });
        });
    }

    render() {

        const { settledTransactions } = this.state;
        const heading = <h2>Abgeschlossene Transaktionen</h2>
        const loading = <div>loading...</div>

        const content = (
            <div>
                <ReactTable
                    data={settledTransactions}
                    columns={[
                        {
                            Header: "Datum",
                            accessor: "date",
                            Cell: row => (
                                <span>{(new Date(row.value)).toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
                            )
                        },
                        {
                            Header: "von",
                            accessor: "userdebit.handle"
                        },
                        {
                            Header: "an",
                            accessor: "usercredit.handle"
                        },
                        {
                            Header: "Betrag",
                            accessor: "balance"
                        }
                    ]}
                    defaultPageSize={25}
                    defaultSorted={[
                        {
                            id: "date",
                            desc: true
                        }
                    ]}
                    style={
                        { height: "500px" }
                    }
                    className="-striped -highlight"
                    filterable
                    defaultFilterMethod={(filter, row) => filterInsensitive(filter, row)}
                />
            </div>
        )
        return (
            <div>
                {heading}
                {settledTransactions.length !== 0 ? content : loading}
            </div>
        );
    }
}

export default (SettledCashBalance);