import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { loginUser } from '../actions/authentication';

class Login extends Component {

    handleFormSubmit({ username, password }) {
        //e.preventDefault();
        const user = {
            username: username,
            password: password,
        }
        this.props.loginUser(this.props.history, user);
    }

    render() {
        const { handleSubmit } = this.props;
        const { isAuthenticated } = this.props.auth;
        const loginForm = (
            <div className="container" style={{ marginTop: '50px', width: '700px' }}>
                <h2 style={{ marginBottom: '40px' }}>Login</h2>
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <fieldset className="form-group">
                        <Field
                            component="input"
                            type="text"
                            placeholder="Username"
                            className="form-control"
                            name="username"
                        />
                    </fieldset>
                    <div className="form-group">
                        <Field
                            component="input"
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            name="password"
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                            Login User
                    </button>
                    </div>
                </form>
            </div>
        )
        const loggedInMessage = (
            <p>schon eingeloggt</p>
        )
        return (
            <div className="container" style={{ marginTop: '50px', width: '700px' }}>
                {isAuthenticated ? loggedInMessage : loginForm}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors,
    auth: state.auth
})

export default reduxForm({
    form: 'login'
})(connect(mapStateToProps, { loginUser })(Login));