import React, { Component } from 'react';

export default class Home extends Component {
    render() {
        return (
            <div>
                Home Component
            </div>
        );
    }
}