import React, { Component } from 'react';
import { getCashBalance, fetch, Endpoints, postCashBalance, getOpenCashBalance, confirmCashBalance, cancelCashBalance } from '../../helpers/api';
import ReactTable from "react-table";
import { apiDataToFormData, parseJwt } from '../../helpers/functions';
import { Formik } from 'formik';
import { CashBalanceForm } from './cash-balance-form';


export class OpenCashBalance extends Component {
    constructor() {
        super();

        this.state = {
            openTransactions: []
        }

        this.handleSettle = this.handleSettle.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    componentWillReceiveProps(props) {
        const { refresh } = this.props;
        if (props.refresh !== refresh) {
            this.initValues();
        }
    }

    initValues() {
        getOpenCashBalance().then((response) => {
            this.setState({ openTransactions: response.data });
        });
    }

    handleSettle(row) {
        if (window.confirm("wirklich abrechnen?")) {
            confirmCashBalance(row.original).then((response) => {
                this.initValues();
            }).catch(() => {
                console.log("something went wrong");
            })
        }
        //this.setState({ settleTransaction: { id: {}, userdebit: row.original.user } });
    }

    handleCancel(row) {
        if (window.confirm("wirklich löschen?")) {
            cancelCashBalance(row.original).then((response) => {
                this.initValues();
            }).catch(() => {
                console.log("something went wrong");
            })
        }
    }

    render() {
        const token = localStorage.getItem('token')
        let handle = "";

        if (parseJwt(token).identity) {
            handle = parseJwt(token).identity.handle;
        }

        const { openTransactions } = this.state;
        const heading = <h2>Offene Transaktionen</h2>

        const content = (
            <div>
                <ReactTable
                    data={openTransactions}
                    columns={[
                        {
                            Header: "Datum",
                            accessor: "date",
                            Cell: row => (
                                <span>{(new Date(row.value)).toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
                            )
                        },
                        {
                            Header: "von",
                            accessor: "userdebit.handle"
                        },
                        {
                            Header: "an",
                            accessor: "usercredit.handle"
                        },
                        {
                            Header: "Betrag",
                            accessor: "balance"
                        },
                        {
                            Header: 'abrechnen',
                            Cell: row => (
                                (handle === row.original.usercredit.handle) && <button className="btn" onClick={e => this.handleSettle(row, e)}><i className="fa fa-edit" /> abrechnen</button>
                            ),
                        },
                        {
                            Header: 'loeschen',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleCancel(row, e)}><i className="fa fa-window-close" /> loeschen</button>
                            ),
                        }
                    ]}
                    showPagination={false}
                    pageSize={openTransactions.length}
                    style={
                        { height: "500px" }
                    }
                    className="-striped -highlight"
                />
            </div>
        )
        return (
            <div>
                {openTransactions.length !== 0 && heading}
                {openTransactions.length !== 0 && content}
            </div>
        );
    }
}

export default (OpenCashBalance);