import React, { Component } from 'react';
import { getCashBalance, fetch, Endpoints, postCashBalance } from '../../helpers/api';
import ReactTable from "react-table";
import { apiDataToFormData } from '../../helpers/functions';
import { Formik } from 'formik';
import { CashBalanceForm } from './cash-balance-form';
import { parseJwt } from '../../helpers/functions';


class CashBalance extends Component {
    constructor() {
        super();

        this.state = {
            cashBalance: [],
            settleTransaction: {},
            users: []
        }

        this.handleSave = this.handleSave.bind(this);

        this.handleSettle = this.handleSettle.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        getCashBalance().then((response) => {
            this.setState({ cashBalance: response.data.filter(balance => balance.balance > 0) });
        });
        fetch(Endpoints.USER).then((response) => {
            this.setState({ users: response.data.users })
        });
    }

    handleSave(values) {
        postCashBalance(values).then((response) => {
            this.initValues();
            this.handleCancel();
        }).catch(() => {
            console.log('something went wrong');
        })

    }
    handleCancel() {
        this.setState({ settleTransaction: {} })
    }

    handleSettle(row) {
        this.setState({ settleTransaction: { id: {}, userdebit: row.original.user } });
    }

    render() {
        const { cashBalance, users, settleTransaction } = this.state;
        const heading = <h2>Kassastand</h2>
        const loading = <div>loading...</div>

        const token = localStorage.getItem('token');
        let admin = false;

        if (parseJwt(token).identity) {
            admin = parseJwt(token).identity.admin;
        }

        const formUsers = apiDataToFormData(users, 'id', 'handle');

        const form = (
            <Formik
                initialValues={settleTransaction}
                onSubmit={(values) => this.handleSave(values)}
                render={
                    props => <CashBalanceForm
                        users={formUsers}
                        formErrors={{}}
                        handleCancel={this.handleCancel}
                        {...props} />
                }
                enableReinitialize={true}
            />
        )

        const content = (
            <div>
                <ReactTable
                    data={cashBalance}
                    columns={[
                        {
                            Header: "Kürzel",
                            accessor: "user.handle"
                        },
                        {
                            Header: "Kassastand",
                            accessor: "balance"
                        }/*,
                        {
                            Header: 'abrechnen',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleSettle(row, e)}><i className="fa fa-edit" /> abrechnen</button>
                            ),
                        }*/
                    ]}
                    showPagination={false}
                    pageSize={cashBalance.length}
                    style={
                        { height: "500px" }
                    }
                    className="-striped -highlight"
                />
            </div>
        )

        const content_settle = (
            <div>
                <ReactTable
                    data={cashBalance}
                    columns={[
                        {
                            Header: "Kürzel",
                            accessor: "user.handle"
                        },
                        {
                            Header: "Kassastand",
                            accessor: "balance"
                        },
                        {
                            Header: 'abrechnen',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleSettle(row, e)}><i className="fa fa-edit" /> abrechnen</button>
                            ),
                        }
                    ]}
                    showPagination={false}
                    pageSize={cashBalance.length}
                    style={
                        { height: "500px" }
                    }
                    className="-striped -highlight"
                />
            </div>
        )


        return (
            <div>
                {heading}
                {cashBalance.length === 0 && loading}
                {(!settleTransaction.id && cashBalance.length) > 0 && (admin ? content_settle : content)}
                {settleTransaction.id && form}
            </div>
        );
    }
}

export default (CashBalance);