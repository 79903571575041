import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

import DayPickerInput from 'react-day-picker/DayPickerInput';

export const InterventionForm = ({
    values,
    handleSubmit,
    handleCancel,
    setFieldValue,
    formErrors,
    users,
    polworkareas,
    tecworkareas }) => {

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row">
                <label htmlFor="name" className="col-sm-1 col-form-label">Name</label>
                <div className="col-sm-5">
                    <Field type="text" name="name" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.name}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="date" className="col-sm-1 col-form-label">Datum</label>
                <div className="col-sm-5">
                    <DayPickerInput
                        id="date"
                        name="date"
                        value={values.date}
                        onDayChange={day => setFieldValue('date', day)}
                        inputProps={{ className: "form-control" }} />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.date}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="location" className="col-sm-1 col-form-label">Ort</label>
                <div className="col-sm-5">
                    <Field type="text" name="location" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.location}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="organizer" className="col-sm-1 col-form-label">organisiert v.</label>
                <div className="col-sm-5">
                    <Field type="text" name="organizer" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.organizer}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="comment" className="col-sm-1 col-form-label">Kommentar</label>
                <div className="col-sm-5">
                    <Field type="text" name="comment" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.comment}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="polworkarea" className="col-sm-1 col-form-label">AF (pol.)</label>
                <div className="col-sm-5">
                    <MySelect
                        id="polworkarea"
                        name="polworkarea"
                        value={values.polworkarea}
                        options={polworkareas}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.polworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="tecworkarea" className="col-sm-1 col-form-label">AF (tech.)</label>
                <div className="col-sm-5">
                    <MySelect
                        id="tecworkarea"
                        name="tecworkarea"
                        value={values.tecworkarea}
                        options={tecworkareas}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.tecworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="leader" className="col-sm-1 col-form-label">Interventionsleit.</label>
                <div className="col-sm-5">
                    <MySelect
                        id="leader"
                        name="leader"
                        value={values.leader}
                        options={users}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.leader}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
                <div className="col-sm-5">
                    {typeof formErrors.message === 'string' && <span className="form-error">{formErrors.message}</span>}
                </div>
            </div>
        </form>
    )
}