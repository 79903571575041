import React, { Component } from 'react';
import { getWarehouseBalance, fetchAccounts } from '../../helpers/api';
import ReactTable from "react-table";
import { filterInsensitive, apiDataToFormData } from '../../helpers/functions';
import MySelect from '../ui/my-select';

class WarehouseBalance extends Component {
    constructor() {
        super();

        this.state = {
            warehouseBalance: [],
            warehouseBalanceSelected: [],
            accounts: [],
            selectedAccount: {},
            warehouseSort: 1,
            lagerstandSort: 1
        }

        this.changeWarehouse = this.changeWarehouse.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        getWarehouseBalance().then((response) => {
            let stock_accounts = [];
            for (var stock_entry of response.data) {
                stock_accounts.push(stock_entry.warehouse);
            }
            this.setState({ warehouseBalance: response.data });
            this.setState({
                warehouseBalanceSelected: response.data[0],
                selectedAccount: response.data[0].warehouse,
                accounts: stock_accounts
            });
        });
        // fetchAccounts().then((response) => {
        //     this.setState({ accounts: response.data.accounts });
        // })
    }

    changeWarehouse(name, selectedValue) {
        for (var stock of this.state.warehouseBalance) {
            if (stock.warehouse.id === selectedValue.id) {
                this.setState({ warehouseBalanceSelected: stock });
            }
        }
        this.setState({
            selectedAccount: selectedValue
        });
    }

    sortArticles() {
        this.state.warehouseBalanceSelected.stock.sort((firstEl, secondEl) => {
            if (firstEl.article.name < secondEl.article.name) {
                return -1 * (this.state.warehouseSort);
            }
            if (firstEl.article.name >= secondEl.article.name) {
                return 1 * (this.state.warehouseSort);
            }
            return 0;
        })
        this.setState({
            warehouseBalanceSelected: this.state.warehouseBalanceSelected,
            warehouseSort: this.state.warehouseSort * -1
        })
    }

    sortLagerstand() {
        this.state.warehouseBalanceSelected.stock.sort((firstEl, secondEl) => {
            if (firstEl.balance < secondEl.balance) {
                return 1 * (this.state.lagerstandSort);
            }
            if (firstEl.balance >= secondEl.balance) {
                return -1 * (this.state.lagerstandSort);
            }
            return 0;
        })
        this.setState({ warehouseBalanceSelected: this.state.warehouseBalanceSelected,
            lagerstandSort: this.state.lagerstandSort * -1 })
    }

    render() {
        const { warehouseBalanceSelected, accounts, selectedAccount } = this.state;
        const heading = <h2>Lagerstand</h2>
        const loading = <div>loading..</div>

        const formAccounts = apiDataToFormData(accounts);

        const accountPicker = (accounts && <MySelect
            id="warehouse"
            value={selectedAccount}
            name="warehouse"
            options={formAccounts}
            onChange={(name, selectedValue) => this.changeWarehouse(name, selectedValue)}
            className="form-control"
        />)

        const stockTable = (
            <ReactTable
                data={warehouseBalanceSelected.stock}
                columns={[
                    {
                        Header: "Artikel",
                        accessor: "article.name"
                    },
                    {
                        Header: "Lagerstand",
                        accessor: "balance"
                    }/*,
                        {
                            Header: 'abrechnen',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleSettle(row, e)}><i className="fa fa-edit" /> abrechnen</button>
                            ),
                        }*/
                ]}
                defaultPageSize={25}
                style={
                    { height: "500px" }
                }
                filterable
                className="-striped -highlight"
                defaultFilterMethod={(filter, row) => filterInsensitive(filter, row)}
            />
        )

        const stockTableHtml = (
            <div>
                <table className="pure-table pure-table-bordered" style={{ margin: 10 }}>
                    <thead>
                        <tr>
                            <th onClick={() => this.sortArticles()}>Artikel</th>
                            <th onClick={() => this.sortLagerstand()}>Lagerstand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {warehouseBalanceSelected.stock && warehouseBalanceSelected.stock.map(stockItem => {
                            return (
                                <tr key={stockItem.article.id}>
                                    <td>{stockItem.article.name}</td>
                                    <td>{stockItem.balance}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )

        const content = (
            <div>
                {accountPicker}
                {stockTableHtml}
                {false && stockTable}
            </div>
        )

        return (
            <div>
                {heading}
                {warehouseBalanceSelected.length === 0 ? loading : content}
            </div>
        );
    }
}

export default (WarehouseBalance);