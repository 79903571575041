export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';

export const FETCH_USERS = "FETCH_USERS";
export const CLEAR_USERS = "CLEAR_USERS";

export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const CLEAR_USER = "CLEAR_USER";

export const SAVE_USER_PENDING = "SAVE_USER_PENDING";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_REJECTED = "SAVE_USER_REJECTED";

export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const CLEAR_ARTICLES = "CLEAR_ARTICLES";

export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const CLEAR_ARTICLE = "CLEAR_ARTICLE";

export const SAVE_ARTICLE_PENDING = "SAVE_ARTICLE_PENDING";
export const SAVE_ARTICLE_SUCCESS = "SAVE_ARTICLE_SUCCESS";
export const SAVE_ARTICLE_REJECTED = "SAVE_ARTICLE_REJECTED";

export const FETCH_ERROR = "FETCH_ERROR";