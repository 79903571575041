export function apiDataToFormData(apidata, value = 'id', label = 'name') {
    return apidata.map(x => ({ ...x, value: x[value], label: x[label] }));
}

export function parseJwt(token) {
    if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } else {
        return ""
    }

};

export function filterInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].trim().toLowerCase().replace(/\s/g, '')).includes(filter.value.trim().toLowerCase().replace(/\s/g, ''))
        :
            true
    );
}