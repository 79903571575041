import { GET_ERRORS, FETCH_ERROR }  from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_ERRORS:
            return action.payload;
        case FETCH_ERROR:
            return action.data;
        default:
            return state;
    }
}