import React from 'react';
import ReactTable from "react-table";

export function CrudTable(props) {
    const { data, handleEdit, columns } = props;

    return (
        <ReactTable
            data={data}
            columns={columns.concat(
                {
                    Header: 'bearbeiten',
                    Cell: row => (
                        <button className="btn" onClick={e => handleEdit(row, e)}><i className="fa fa-pencil" /> bearbeiten</button>
                    ),
                }
            )}
            showPagination={false}
            pageSize={data.length}
            style={
                { height: "500px" }
            }
            className="-striped -highlight"
        />
    )
}