import * as ActionType from '../actions/types';

const defaultState = {
    users: [],
    user: {},
    loading: false,
    errors: {}
}

export default function(state = defaultState, action) {
    switch(action.type) {
        case ActionType.FETCH_USERS:
            return {
                ...state,
                users: action.data
            }
        case ActionType.CLEAR_USERS:
            return {
                ...state,
                users: []
            }
        case ActionType.CREATE_USER:
            return {
                ...state,
                user: { id: {}, author: {}, name: {}, price: {}, comment: {}}
            }
        case ActionType.EDIT_USER:
            return {
                ...state,
                user: action.data
            }
        case ActionType.SAVE_USER_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.CLEAR_USER:
            return {
                ...state,
                user: {},
                errors: {}
            }
        case ActionType.SAVE_USER_SUCCESS:
            let user = state.users.find(obj => obj.id === action.data.id)

            if (user) {
                user.name = action.data.name;
                user.author = action.data.author;
                user.price = action.data.price;
                user.comment = action.data.comment;
            } else {
                state.users.push(action.data);
            }

            return {
                ...state,
                users: [...state.users],
                user: {},
                errors: {},
                loading: false
            }
        case ActionType.SAVE_USER_REJECTED:
            return {
                ...state,
                errors: action.data
            }
        default: 
            return state;
    }
}