import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

export const UserForm = ({ values, handleSubmit, handleCancel, setFieldValue, ogs, formErrors }) => {
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row">
                <label htmlFor="handle" className="col-sm-1 col-form-label">Kürzel</label>
                <div className="col-sm-5">
                    <Field type="text" name="handle" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.handle}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="email" className="col-sm-1 col-form-label">E-Mail</label>
                <div className="col-sm-5">
                    <Field type="text" name="email" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.email}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="og" className="col-sm-1 col-form-label">OG</label>
                <div className="col-sm-5">
                    <MySelect
                        id="og"
                        value={values.og}
                        name="og"
                        labelKey="name"
                        options={ogs}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.og}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
                <div className="col-sm-5">
                    {typeof formErrors.message === 'string' && <span className="form-error">{formErrors.message}</span>}
                </div>
            </div>
        </form>
    )
}