import React, { Component } from 'react';
import { Endpoints, fetch, save } from '../../helpers/api';
import { Formik } from 'formik';
import { CrudTable } from '../ui/crud-table';
import { ArticleForm } from './ArticleForm';
import { apiDataToFormData } from '../../helpers/functions';

class Article extends Component {
    constructor() {
        super();

        this.state = {
            articles: [],
            selectedArticle: {},
            articleCategories: [],
            errors: {}
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetch(Endpoints.ARTICLE).then((response) => {
            this.setState({ articles: response.data.articles });
        })

        fetch(Endpoints.ARTICLECATEGORY).then((response) => {
            this.setState({ articleCategories: response.data.articlecategories });
        })
    }

    setSelectedArticle(article) {
        this.setState({ selectedArticle: { ...article } });
    }

    setErrors(errors) {
        this.setState({ errors: errors });
    }

    clearValues() {
        this.setSelectedArticle({});
        this.setErrors({});
    }

    handleNew() {
        this.setSelectedArticle({ id: {}, name: '', author: '', price: {}, comment: '' });
    }

    handleEdit(row) {
        this.setSelectedArticle(row.original);
    }

    handleCancel() {
        this.clearValues();
    }

    handleSave(values) {
        const category = values.category.id ? values.category : this.state.articleCategories.find(x => x.id === values.category.value);

        save(Endpoints.ARTICLE, {
            ...this.state.selectedArticle,
            author: values.author && values.author.length !== 0 ? values.author : undefined,
            name: values.name && values.name.length !== 0 ? values.name : undefined,
            price: values.price && values.price.length !== 0 ? values.price : undefined,
            comment: values.comment,
            category: category
        }).then(() => {
            this.initValues();
            this.clearValues();
        }).catch(error => {
            const response = error.response;
            this.setErrors(response.data);
        });
    }

    render() {
        const { selectedArticle, articles, errors, articleCategories } = this.state;

        const formArticleCategories = apiDataToFormData(articleCategories);

        const heading = <h2>Artikel</h2>
        const loading = <div>loading...</div>
        const addArticleButton = (
            <button className="btn" onClick={this.handleNew}>
                <i className="fa fa-plus" />
                hinzufügen
            </button>
        )

        const form = (
            <Formik
                initialValues={selectedArticle}
                onSubmit={(values) => this.handleSave(values)}
                render={
                    props => <ArticleForm
                        formErrors={errors}
                        handleCancel={this.handleCancel}
                        articleCategories={formArticleCategories}
                        {...props} />
                }
                enableReinitialize={true}
            />
        )

        const content = (
            <div>
                <CrudTable
                    data={this.state.articles}
                    handleEdit={this.handleEdit}
                    columns={[
                        {
                            Header: "Autor",
                            accessor: "author",
                            maxWidth: 250
                        },
                        {
                            Header: "Name",
                            accessor: "name",
                            maxWidth: 350
                        },
                        {
                            Header: "Preis",
                            accessor: "price",
                            maxWidth: 100
                        },
                        {
                            Header: "Kommentar",
                            accessor: "comment",
                            maxWidth: 350
                        },
                        {
                            Header: "Kategorie",
                            accessor: "category.name",
                            maxWidth: 350
                        }
                    ]}
                />
            </div>
        )

        return (
            <div>
                {heading}
                {articles.length > 0 ? content : loading}
                {selectedArticle.id ? form : addArticleButton}
            </div>
        );
    }

}

export default (Article);