import axios from 'axios';
import { parseJwt } from './helpers/functions';

const setAuthToken = token => {
    if(Object.keys(token).length !== 0) {
        axios.defaults.headers.common['Authorization'] = JSON.stringify(parseJwt(token).identity);
    }
    else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken;