import { AUTH_USER, UNAUTH_USER, AUTH_ERROR } from '../actions/types';
import isEmpty from '../validation/is-empty';

export default function(state = {}, action) {
    switch(action.type) {
        case AUTH_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload)
            }
        default: 
            return state;
    }
}