import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../actions/authentication';
import { withRouter } from 'react-router-dom';
import { parseJwt } from '../helpers/functions';

class Navbar extends Component {

    onLogout(e) {
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    }

    render() {
        const token = localStorage.getItem('token')
        let handle = "";

        if (parseJwt(token).identity) {
            handle = parseJwt(token).identity.handle;
        }
        const loginItem = (
            <li className="pure-menu-item">
                <Link className="pure-menu-link" to="/login">Sign In</Link>
            </li>
        )
        const logoutItem = (
            <li className="pure-menu-item">
                <a href="#" className="pure-menu-link" onClick={this.onLogout.bind(this)}>Logout {handle}</a>
            </li>
        )

        const menu = (
            <div>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/profile">Profil</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/user">User</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/article">Artikel</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/og">OG</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/intervention">Intervention</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/sale">Verkauf</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/balance">Bilanz</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/cashbalance">Kassastand</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/opencashbalance">Abrechnungen</Link>
                </li>
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to="/warehousebalance">Lagerstand</Link>
                </li>
            </div>
        )

        return (
            <nav>
                <div className="pure-menu">
                    <ul className="pure-menu-list">
                        {token ? logoutItem : loginItem}
                        {token && menu}
                    </ul>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));