import * as ActionType from '../actions/types';

const defaultState = {
    articles: [],
    article: {},
    loading: false,
    errors: {}
}

export default function(state = defaultState, action) {
    switch(action.type) {
        case ActionType.FETCH_ARTICLES:
            return {
                ...state,
                articles: action.data
            }
        case ActionType.CLEAR_ARTICLES:
            return {
                ...state,
                articles: []
            }
        case ActionType.CREATE_ARTICLE:
            return {
                ...state,
                article: { id: {}, author: {}, name: {}, price: {}, comment: {}}
            }
        case ActionType.EDIT_ARTICLE:
            return {
                ...state,
                article: action.data
            }
        case ActionType.SAVE_ARTICLE_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.CLEAR_ARTICLE:
            return {
                ...state,
                article: {},
                errors: {}
            }
        case ActionType.SAVE_ARTICLE_SUCCESS:
            let article = state.articles.find(obj => obj.id === action.data.id)

            if (article) {
                article.name = action.data.name;
                article.author = action.data.author;
                article.price = action.data.price;
                article.comment = action.data.comment;
            } else {
                state.articles.push(action.data);
            }

            return {
                ...state,
                articles: [...state.articles],
                article: {},
                errors: {},
                loading: false
            }
        case ActionType.SAVE_ARTICLE_REJECTED:
            return {
                ...state,
                errors: action.data
            }
        default: 
            return state;
    }
}