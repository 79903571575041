import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

import DayPickerInput from 'react-day-picker/DayPickerInput';

export const InterventionSettleForm = ({handleCancel}) => {

    return (
        <form autoComplete="off">
            <div className="form-group row">
                <label htmlFor="name" className="col-sm-1 col-form-label">Name</label>
                <div className="col-sm-5">
                    <Field type="text" name="name" className="form-control" />
                </div>
            </div>          

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
            </div>
        </form>
    )
}