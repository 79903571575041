import React, { Component } from 'react';
import { Endpoints, fetch, save } from '../../helpers/api';
import { Formik } from 'formik';
import { apiDataToFormData, filterInsensitive } from '../../helpers/functions';
import { SaleForm } from './sale-form';
import ReactTable from "react-table";

class Sale extends Component {
    constructor() {
        super();

        this.state = {
            sales: [],
            selectedSale: {},
            errors: {},
            users: [],
            articles: [],
            ogs: [],
            polworkareas: [],
            tecworkareas: [],
            interventions: [],
            showDetails: false
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDetails = this.handleDetails.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetch(Endpoints.SALE).then((response) => {
            this.setState({ sales: response.data.sales });
        })
        fetch(Endpoints.POLWORKAREA).then((response) => {
            this.setState({ polworkareas: response.data.polworkareas });
        })
        fetch(Endpoints.TECWORKAREA).then((response) => {
            this.setState({ tecworkareas: response.data.tecworkareas });
        })
        fetch(Endpoints.USER).then((response) => {
            this.setState({ users: response.data.users });
        })
        fetch(Endpoints.INTERVENTION).then((response) => {
            this.setState({ interventions: response.data.interventions });
        })
        fetch(Endpoints.ARTICLE).then((response) => {
            this.setState({ articles: response.data.articles });
        })
        fetch(Endpoints.OG).then((response) => {
            this.setState({ ogs: response.data.ogs });
        })
    }

    setSelectedSale(sale) {
        this.setState({ selectedSale: { ...sale } });
    }

    setErrors(errors) {
        this.setState({ errors: errors });
    }

    clearValues() {
        this.setSelectedSale({});
        this.setErrors({});
    }

    handleNew() {
        this.setSelectedSale({
            id: {},
            seller: {},
            og: {},
            transaction_monetary: { date: 0, quantity: 0 },
            transaction_stock: { article: {}, date: {}, quantity: 0 },
            polworkarea: {},
            tecworkarea: {},
            intervention: {},
            comment: '',
            paid: true
        });
    }

    handleDetails() {
        this.setState({ showDetails: !this.state.showDetails });
    }

    handleCancel() {
        this.clearValues();
    }

    handleEdit(row) {
        this.clearValues();
        this.setSelectedSale(row.original);
    }

    handleSave(values) {
        
        let date_value = values.date;

        if (values.intervention === null) {
            values.intervention = { id: {} };
        }

        if (values.tecworkarea === null) {
            values.tecworkarea = { id: {} };
        }

        if (date_value === undefined) {
            date_value = values.transaction_stock.date;
        }

        const polworkarea = values.polworkarea.id ? values.polworkarea : this.state.polworkareas.find(x => x.id === values.polworkarea.value);
        const tecworkarea = values.tecworkarea.id ? values.tecworkarea : this.state.tecworkareas.find(x => x.id === values.tecworkarea.value);
        const seller = values.seller.id ? values.seller : (values.seller.value && this.state.users.find(x => x.id === values.seller.value));
        const intervention = values.intervention.id ? values.intervention : (values.intervention.value && this.state.interventions.find(x => x.id === values.intervention.value));
        const og = values.og.id ? values.og : (values.og.value && this.state.ogs.find(x => x.id === values.og.value));
        const article = values.transaction_stock.article.id ? values.transaction_stock.article : (values.transaction_stock.article.value && this.state.articles.find(x => x.id === values.transaction_stock.article.value));

        save(Endpoints.SALE, {
            ...this.state.selectedSale,
            date: date_value,
            comment: values.comment && values.comment.length !== 0 ? values.comment : undefined,
            polworkarea: polworkarea,
            tecworkarea: tecworkarea,
            seller: seller,
            intervention: intervention,
            og: og,
            article: article,
            quantity: values.transaction_stock.quantity,
            money: values.transaction_monetary.quantity,
            paid: values.paid
        }).then(() => {
            this.initValues();
            this.clearValues();
        }).catch(error => {
            const response = error.response;
            this.setErrors(response.data);
        });

    }

    exportToExcel() {
        var output = "";
        output += "Datum\tverkauft von\tArtikel\tStk\tGeld\tPolArbeitsfeld\tTecArbeitsfeld\tIntervention\tOG\tKommentar\n"
        for (var row of this.detailTable.getResolvedState().sortedData) {
            output +=
                (row._original.transaction_stock.date + "").split("T")[0] + "\t" +
                row._original.seller.handle + "\t" +
                row._original.transaction_stock.article.name + "\t" +
                row._original.transaction_stock.quantity + "\t" +
                (row._original.transaction_monetary.quantity + "").replace(".", ",") + "\t" +
                row._original.polworkarea.name + "\t" +
                (row._original.tecworkarea !== null ? row._original.tecworkarea.name : "") + "\t" +
                (row._original.intervention !== null ? row._original.intervention.name : "") + "\t" +
                row._original.og.name + "\t" +
                (row._original.comment !== null ? row._original.comment : "") + "\n";
        }

        var el = document.createElement('textarea');
        el.value = output;
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    render() {
        const { selectedSale, sales, polworkareas, tecworkareas, users, articles, interventions, ogs, errors, showDetails } = this.state;

        const heading = <div><h2>Verkauf</h2></div>
        const detailsButton = <button className="btn" onClick={this.handleDetails}>
            show/hide Details
</button>
        const loading = <div>loading...</div>

        const addButton = (
            <button className="btn" onClick={this.handleNew}>
                <i className="fa fa-plus" />
                hinzufügen
            </button>
        )

        const excelButton = (
            <button className="btn" onClick={this.exportToExcel}>
                für Excel kopieren
            </button>
        )

        const formPolworkareas = apiDataToFormData(polworkareas);
        const formTecworkareas = apiDataToFormData(tecworkareas);
        const formUsers = apiDataToFormData(users, 'id', 'handle');
        const formArticles = apiDataToFormData(articles);
        const formInterventions = apiDataToFormData(interventions);
        const formOgs = apiDataToFormData(ogs);

        const form = (
            <Formik
                initialValues={selectedSale}
                onSubmit={(values) => this.handleSave(values)}
                render={
                    props => <SaleForm
                        polworkareas={formPolworkareas}
                        tecworkareas={formTecworkareas}
                        users={formUsers}
                        articles={formArticles}
                        interventions={formInterventions}
                        ogs={formOgs}
                        formErrors={errors}
                        handleCancel={this.handleCancel}
                        {...props} />
                }
                enableReinitialize={true}
            />
        )

        const simpleContent = (
            <div>
                <ReactTable
                    data={sales}
                    columns={[
                        {
                            Header: "Datum",
                            accessor: "transaction_stock.date",
                            Cell: row => (
                                <span>{(new Date(row.value)).toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
                            )
                        },
                        {
                            Header: "von",
                            accessor: "seller.handle"
                        },
                        {
                            Header: "Artikel",
                            accessor: "transaction_stock.article.name"
                        },
                        {
                            Header: "Stk",
                            accessor: "transaction_stock.quantity"
                        },
                        {
                            Header: "$",
                            accessor: "transaction_monetary.quantity"
                        },
                        {
                            Header: "OG",
                            accessor: "og.name"
                        },
                        {
                            Header: "bezahlt",
                            accessor: "transaction_monetary.approved",
                            Cell: row => (
                                <span style={{color: (row.value) ? 'green': 'red'}}>{row.value ? 'ja' : 'nein'}</span>
                            )
                        },
                        {
                            Header: 'bearbeiten',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleEdit(row, e)}><i className="fa fa-pencil" /> bearbeiten</button>
                            ),
                        }
                    ]}
                    defaultPageSize={25}
                    style={
                        { height: "500px" }
                    }
                    defaultSorted={[
                        {
                            id: "transaction_stock.date",
                            desc: true
                        }
                    ]}
                    className="-striped -highlight"
                    filterable
                    defaultFilterMethod={(filter, row) => filterInsensitive(filter, row)}
                />
            </div>
        )

        const detailedContent = (
            <div>
                <ReactTable
                    data={sales}
                    ref={(r) => this.detailTable = r}
                    columns={[
                        {
                            Header: "Datum",
                            accessor: "transaction_stock.date",
                            Cell: row => (
                                <span>{(new Date(row.value)).toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
                            )
                        },
                        {
                            Header: "von",
                            accessor: "seller.handle"
                        },
                        {
                            Header: "Artikel",
                            accessor: "transaction_stock.article.name"
                        },
                        {
                            Header: "Stk",
                            accessor: "transaction_stock.quantity"
                        },
                        {
                            Header: "$",
                            accessor: "transaction_monetary.quantity"
                        },
                        {
                            Header: "Arbeitsfeld (pol.)",
                            accessor: "polworkarea.name"
                        },
                        {
                            Header: "Arbeitsfeld (tech.)",
                            accessor: "tecworkarea.name"
                        },
                        {
                            Header: "Intervention",
                            accessor: "intervention.name",
                            filterMethod: (filter, row) =>
                                row[filter.id] != null && row[filter.id].trim().toLowerCase().replace(/\s/g, '').includes(filter.value.trim().toLowerCase().replace(/\s/g, ''))
                        },
                        {
                            Header: "OG",
                            accessor: "og.name"
                        },
                        {
                            Header: "Kommentar",
                            accessor: "comment"
                        },
                        {
                            Header: "bezahlt",
                            accessor: "transaction_monetary.approved",
                            Cell: row => (
                                <span style={{color: (row.value) ? 'green': 'red'}}>{row.value ? 'ja' : 'nein'}</span>
                            )
                        },
                        {
                            Header: 'bearbeiten',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleEdit(row, e)}><i className="fa fa-pencil" /> bearbeiten</button>
                            ),
                        },
                        {
                            Header: "gebucht",
                            accessor: "booker.handle",
                            filterMethod: (filter, row) =>
                                row[filter.id] != null && (row[filter.id].trim().toLowerCase().replace(/\s/g, '') == (filter.value.trim().toLowerCase().replace(/\s/g, '')))
          
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: "transaction_stock.date",
                            desc: true
                        }
                    ]}
                    defaultPageSize={25}
                    style={
                        { height: "500px" }
                    }
                    filterable
                    className="-striped -highlight"
                    defaultFilterMethod={(filter, row) => filterInsensitive(filter, row)}
                />
            </div>
        )

        return (
            <div>
                {heading}
                {!selectedSale.id && detailsButton}
                {!selectedSale.id && (sales.length > 0 ? (showDetails ? detailedContent : simpleContent) : loading)}
                {!selectedSale.id && showDetails && excelButton}
                {(selectedSale.id
                    && polworkareas.length > 0
                    && tecworkareas.length > 0
                    && users.length > 0
                    && articles.length > 0
                    && ogs.length > 0) ? form : addButton}
            </div >
        );
    }
}

export default (Sale);