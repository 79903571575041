import React, { Component } from 'react';
import { Endpoints, fetch, save } from '../../helpers/api';
import { Formik } from 'formik';
import { CrudTable } from '../ui/crud-table';
import { apiDataToFormData } from '../../helpers/functions';
import { InterventionForm } from './intervention-form';
import { InterventionSettleForm } from './intervention-settle-form';

class Intervention extends Component {
    constructor() {
        super();

        this.state = {
            interventions: [],
            selectedIntervention: {},
            settleIntervention: {},
            errors: {},
            polworkareas: [],
            tecworkareas: [],
            users: []
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetch(Endpoints.INTERVENTION).then((response) => {
            this.setState({ interventions: response.data.interventions });
        })
        fetch(Endpoints.POLWORKAREA).then((response) => {
            this.setState({ polworkareas: response.data.polworkareas });
        })
        fetch(Endpoints.TECWORKAREA).then((response) => {
            this.setState({ tecworkareas: response.data.tecworkareas });
        })
        fetch(Endpoints.USER).then((response) => {
            this.setState({ users: response.data.users });
        })
    }

    setSelectedIntervention(intervention) {
        this.setState({ selectedIntervention: { ...intervention } });
    }

    setSettleIntervention(intervention) {
        this.setState({ settleIntervention: { ...intervention } });
    }

    setErrors(errors) {
        this.setState({ errors: errors });
    }

    clearValues() {
        this.setSelectedIntervention({});
        this.setSettleIntervention({});
        this.setErrors({});
    }

    handleNew() {
        this.setSelectedIntervention({
            id: {},
            name: '',
            date: '',
            location: '',
            comment: '',
            polworkarea: {},
            tecworkarea: {},
            leader: {}
        });
    }

    handleEdit(row) {
        this.setSelectedIntervention(row.original);
    }

    handleCancel() {
        this.clearValues();
    }

    handleSettle(row) {
        this.setSettleIntervention(row.original);
    }

    handleSave(values) {
        console.log(values);

        if (values.leader === null) {
            values.leader = { id: {} };
        }

        const polworkarea = values.polworkarea.id ? values.polworkarea : this.state.polworkareas.find(x => x.id === values.polworkarea.value);
        const tecworkarea = values.tecworkarea.id ? values.tecworkarea : this.state.tecworkareas.find(x => x.id === values.tecworkarea.value);
        const leader = values.leader.id ? values.leader : (values.leader.value && this.state.users.find(x => x.id === values.leader.value));

        save(Endpoints.INTERVENTION, {
            ...this.state.selectedIntervention,
            name: values.name && values.name.length !== 0 ? values.name : undefined,
            date: values.date,
            location: values.location && values.location.length !== 0 ? values.location : undefined,
            organizer: values.organizer && values.organizer.length !== 0 ? values.organizer : undefined,
            comment: values.comment && values.comment.length !== 0 ? values.comment : undefined,
            polworkarea: polworkarea,
            tecworkarea: tecworkarea,
            leader: leader
        }).then(() => {
            this.initValues();
            this.setSelectedIntervention({});
        }).catch(error => {
            const response = error.response;
            this.setErrors(response.data);
        });
    }

    render() {
        const { selectedIntervention, interventions, polworkareas, tecworkareas, users, errors, settleIntervention } = this.state;

        const heading = <h2>Intervention</h2>
        const loading = <div>loading...</div>

        const addButton = (
            <button className="btn" onClick={this.handleNew}>
                <i className="fa fa-plus" />
                hinzufügen
            </button>
        )

        const formPolworkareas = apiDataToFormData(polworkareas);
        const formTecworkareas = apiDataToFormData(tecworkareas);
        const formUsers = apiDataToFormData(users, 'id', 'handle');

        const form = (
            <Formik
                initialValues={selectedIntervention}
                onSubmit={(values) => this.handleSave(values)}
                render={
                    props => <InterventionForm
                        polworkareas={formPolworkareas}
                        tecworkareas={formTecworkareas}
                        users={formUsers}
                        formErrors={errors}
                        handleCancel={this.handleCancel}
                        {...props} />
                }
                enableReinitialize={true}
            />
        )

        const content = (
            <div>
                <CrudTable
                    data={this.state.interventions}
                    handleEdit={this.handleEdit}
                    columns={[
                        {
                            Header: "Name",
                            accessor: "name"
                        },
                        {
                            Header: "Datum",
                            accessor: "date",
                            Cell: row => (
                                <span>{(new Date(row.value)).toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
                            )
                        },
                        {
                            Header: "Ort",
                            accessor: "location"
                        },
                        {
                            Header: "organisiert von",
                            accessor: "organizer"
                        },
                        {
                            Header: "Kommentar",
                            accessor: "comment"
                        },
                        {
                            Header: "Arbeitsfeld (pol.)",
                            accessor: "polworkarea.name"
                        },
                        {
                            Header: "Arbeitsfeld (tech.)",
                            accessor: "tecworkarea.name"
                        },
                        {
                            Header: "Interventionsleitung",
                            accessor: "leader.handle"
                        },
                        /*
                        {
                            Header: 'abrechnen',
                            Cell: row => (
                                <button className="btn" onClick={e => this.handleSettle(row, e)}><i className="fa fa-edit" /> abrechnen</button>
                            ),
                        }*/
                    ]}
                />
            </div>
        )

        return (
            <div>
                {heading}
                {interventions.length === 0 && loading}
                {interventions.length > 0 && !selectedIntervention.id && !settleIntervention.id && [content, addButton]}
                {settleIntervention.id &&
                    <Formik
                        render={
                            props => <InterventionSettleForm handleCancel={this.handleCancel} {...props} />
                        }
                        enableReinitialize={true}
                    />}

                {(selectedIntervention.id
                    && polworkareas.length > 0
                    && tecworkareas.length > 0
                    && users.length > 0) && form }
            </div >
        );
    }
}


export default (Intervention);