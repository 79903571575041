import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css';

import Navbar from './components/Navbar';
import Login from './components/Login';
import Home from './components/Home';
import User from './components/user/user';
import Article from './components/article/Article';
import Og from './components/og/og';
import Sale from './components/sale/sale';
import Balance from './components/balance/balance';
import Profile from './components/profile/profile';

import { setCurrentUser } from './actions/authentication';
import Intervention from './components/intervention/intervention';
import CashBalance from './components/balance/cash-balance';
import SettledCashBalance from './components/balance/settled-balance';
import OpenCashBalance from './components/balance/open-cash-balance';
import WarehouseBalance from './components/balance/warehouse-balance';

const token = localStorage.getItem('token');

if (token) {
  store.dispatch(setCurrentUser(token));
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
          <Router>
            <div className="hg">
            <div className="hg__header">
            <img src="funke.jpg" height="50px" alt="header"></img>
            </div>
              <Navbar className="hg__left" />
              <div className="hg__main">
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/user" component={User} />
                <Route exact path="/article" component={Article} />
                <Route exact path="/og" component={Og} />
                <Route exact path="/intervention" component={Intervention} />
                <Route exact path="/sale" component={Sale} />
                <Route exact path="/balance" component={Balance} />
                <Route exact path="/cashbalance" component={CashBalance} />
                <Route exact path="/opencashbalance" component={SettledCashBalance} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/warehousebalance" component={WarehouseBalance} />
              </div>
            </div>
          </Router>
      </Provider>
    );
  }
}

export default App;
