import React, { Component } from 'react';
import { fetchUsers, fetchOgs, fetchAccounts, saveOg } from '../../helpers/api';
import { Formik } from 'formik';
import { OgForm } from './og-form';
import { CrudTable } from '../ui/crud-table';
import { apiDataToFormData } from '../../helpers/functions';

class Og extends Component {
    constructor() {
        super();

        this.state = {
            ogs: [],
            users: [],
            accounts: [],
            selectedOg: {},
            errors: {}
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetchUsers().then((response) => {
            this.setState({ users: response.data.users });
        })
        fetchOgs().then((response) => {
            this.setState({ ogs: response.data.ogs });
        })
        fetchAccounts().then((response) => {
            this.setState({ accounts: response.data.accounts });
        })
    }

    setSelectedOg(og) {
        this.setState({ selectedOg: { ...og } });
    }

    handleNew() {
        this.setSelectedOg({ id: {}, name: "", salesmgr: {}, warehouse: {} });
    }

    handleEdit(row) {
        this.setSelectedOg(row.original);
    }

    handleCancel() {
        this.setSelectedOg({});
    }

    handleSave(values) {
        const salesmgr = values.salesmgr.id ? values.salesmgr : this.state.users.find(x => x.id === values.salesmgr.value);
        const warehouse = values.warehouse.id ? values.warehouse : this.state.accounts.find(x => x.id === values.warehouse.value);

        saveOg({
            ...this.state.selectedOg,
            name: values.name && values.name.length !== 0 ? values.name : undefined,
            salesmgr: salesmgr,
            warehouse: warehouse
        }).then(() => {
            this.initValues();
            this.setSelectedOg({});
        });
    }

    render() {
        const { selectedOg, users, ogs, accounts } = this.state;

        const heading = <h2>OG</h2>
        const loading = <div>loading...</div>
        const addOgButton = <button className="btn" onClick={this.handleNew}><i className="fa fa-plus" /> hinzufügen</button>

        const formUsers = apiDataToFormData(users, 'id', 'handle');
        const formAccounts = apiDataToFormData(accounts);

        const form = (
            <Formik
                initialValues={selectedOg}
                onSubmit={(values) => this.handleSave(values)}
                render={props => <OgForm users={formUsers} accounts={formAccounts} handleCancel={this.handleCancel} {...props} />}
                enableReinitialize={true}
            />
        )

        const content = (
            <div>
                <CrudTable
                    data={this.state.ogs}
                    handleEdit={this.handleEdit}
                    columns={[
                        {
                            Header: "Name",
                            accessor: "name",
                            maxWidth: 150
                        },
                        {
                            Header: "Mitglieder",
                            accessor: "members",
                            Cell: row => (
                                row.value.map((member) => {
                                    return <span key={member.id}>- {member.handle}  </span>
                                })
                            ),
                            minWidth: 300
                        },
                        {
                            Header: "Lager",
                            accessor: "warehouse.name",
                            maxWidth: 150
                        },
                        {
                            Header: "VertrieblerIn",
                            accessor: "salesmgr.handle",
                            maxWidth: 100
                        }
                    ]}
                />
            </div>
        )

        return (
            <div>
                {heading}
                {ogs.length > 0 ? content : loading}
                {(selectedOg.id && users.length > 0 && accounts.length > 0) ? form : addOgButton}
            </div >
        );
    }
}

export default (Og);