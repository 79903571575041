import React, { Component } from 'react';
import { Endpoints, fetch, getBalance } from '../../helpers/api';
import { apiDataToFormData } from '../../helpers/functions';
import { Formik } from 'formik';
import { BalanceForm } from './balance-form';

class Balance extends Component {
    constructor() {
        super();

        this.state = {
            ogs: [],
            articles: [],
            article_categories: [],
            errors: {},
            balance: {}
        }
        this.handleRequest = this.handleRequest.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.initValues();
    }

    initValues() {
        fetch(Endpoints.OG).then((response) => {
            this.setState({ ogs: response.data.ogs });
        })
        fetch(Endpoints.ARTICLE).then((response) => {
            this.setState({ articles: response.data.articles });
        })
        fetch(Endpoints.ARTICLECATEGORY).then((response) => {
            this.setState({ article_categories: response.data.articlecategories });
        })
    }

    handleRequest(values) {
        const req = {
            selectedOgs: values.selectedOgs,
            selectedArticles: values.selectedArticles,
            selectedCategories: values.selectedCategories,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo
        }
        getBalance(req).then((response) => {
            let bal_arr = [];

            Object.keys(response.data.balance.og_results).forEach((k, i) => {
                if (response.data.balance.og_results[k] && 
                    !response.data.balance.og_results[k].og.name.toLowerCase().includes("archiv")) {
                    bal_arr.push(response.data.balance.og_results[k])
                }                
            })

            bal_arr.sort((a,b) => {
                return ('' + a.og.name).localeCompare(b.og.name);
            })

            response.data.balance.og_results = bal_arr;

            response.data.balance.personal_results = Object.fromEntries(
                Object.entries(response.data.balance.personal_results).sort((a,b) => b[1].totalsold-a[1].totalsold)
            );

            response.data.balance.polworkarea_results = Object.fromEntries(
                Object.entries(response.data.balance.polworkarea_results).sort((a,b) => b[1].totalsold-a[1].totalsold)
            );

            response.data.balance.tecworkarea_results = Object.fromEntries(
                Object.entries(response.data.balance.tecworkarea_results).sort((a,b) => b[1].totalsold-a[1].totalsold)
            );

            response.data.balance.intervention_results = Object.fromEntries(
                Object.entries(response.data.balance.intervention_results).sort((a,b) => b[1].totalsold-a[1].totalsold)
            );

            response.data.balance.article_results = Object.fromEntries(
                Object.entries(response.data.balance.article_results).sort((a,b) => b[1].totalsold-a[1].totalsold)
            );



            console.log(response.data.balance);

            this.setState({ balance: response.data.balance });
        }).catch(() => {
        });
    }

    handleCancel() {
        this.setState({ balance: {} });
    }

    render() {
        const { ogs, articles, errors, balance, article_categories } = this.state;
        const heading = <h2>Bilanz</h2>
        const loading = <div>loading...</div>

        const formOgs = apiDataToFormData(ogs);
        const formArticles = apiDataToFormData(articles);
        const formArticleCategories = apiDataToFormData(article_categories)

        const form = (
            <Formik
                onSubmit={(values) => this.handleRequest(values)}
                render={
                    props => <BalanceForm
                        ogs={formOgs}
                        articles={formArticles}
                        articleCategories={formArticleCategories}
                        formErrors={errors}
                        handleCancel={this.handleCancel}
                        {...props} />
                }
            />
        )

        const og_balance_table = (
            <div>
                <table className="pure-table pure-table-bordered" style={{ margin: 10 }}>
                    <thead>
                        <tr>
                            <th scope="col">wer</th>
                            <th scope="col">Stk verkauft</th>
                            <th scope="col">$</th>
                            <th scope="col">&lt;5</th>
                            <th scope="col">&gt;=5</th>
                            <th scope="col">&gt;=10</th>
                            <th scope="col">insgesamt beteiligt</th>
                            <th scope="col">an intern</th>
                            <th scope="col">an extern</th>
                            <th scope="col">an Kontakte</th>
                        </tr>
                    </thead>
                    {Object.keys(balance).length !== 0 && (<tbody>
                        {Object.keys(balance.og_results).map((k, i) => {
                            let data = balance.og_results[k];
                            return (
                                <tr key={i}>
                                    <td>{data.og.name}</td>
                                    <td>{data.totalsold}</td>
                                    <td>{data.totalmoney.toFixed(2)}</td>
                                    <td>{data.less5}</td>
                                    <td>{data.more5}</td>
                                    <td>{data.more10}</td>
                                    <td>{data.totalsellers}</td>
                                    <td>{data.totalinternal}</td>
                                    <td>{data.totalexternal}</td>
                                    <td>{data.totalcontacts}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td>total</td>
                            <td>{balance.total_results.totalsold}</td>
                            <td>{balance.total_results.totalmoney.toFixed(2)}</td>
                            <td>{balance.total_results.less5}</td>
                            <td>{balance.total_results.more5}</td>
                            <td>{balance.total_results.more10}</td>
                            <td>{balance.total_results.totalsellers}</td>
                            <td>{balance.total_results.totalinternal}</td>
                            <td>{balance.total_results.totalexternal}</td>
                            <td>{balance.total_results.totalcontacts}</td>
                        </tr>

                    </tbody>)}
                </table>
            </div>
        )

        const user_balance_table = (
            <div>
                <table className="pure-table pure-table-bordered" style={{ float: 'left', margin: 10 }}>
                    <thead>
                        <tr>
                            <th scope="col">pol Arbeitsfeld</th>
                            <th scope="col">Stk verkauft</th>
                            <th scope="col">$</th>
                        </tr>
                    </thead>
                    {Object.keys(balance).length !== 0 && (<tbody>
                        {Object.keys(balance.polworkarea_results).sort((a,b) => a.totalsold - b.totalsold).map((k, i) => {
                            let data = balance.polworkarea_results[k];
                            return (
                                <tr key={i}>
                                    <td>{k}</td>
                                    <td>{data.totalsold}</td>
                                    <td>{data.totalmoney.toFixed(2)}</td>
                                </tr>
                            )
                        })}

                    </tbody>)}
                </table>

                <table className="pure-table pure-table-bordered" style={{ float: 'left', margin: 10 }}>
                    <thead>
                        <tr>
                            <th scope="col">tec Arbeitsfeld</th>
                            <th scope="col">Stk verkauft</th>
                            <th scope="col">$</th>
                        </tr>
                    </thead>
                    {Object.keys(balance).length !== 0 && (<tbody>
                        {Object.keys(balance.tecworkarea_results).map((k, i) => {
                            let data = balance.tecworkarea_results[k];
                            return (
                                <tr key={i}>
                                    <td>{k}</td>
                                    <td>{data.totalsold}</td>
                                    <td>{data.totalmoney.toFixed(2)}</td>
                                </tr>
                            )
                        })}

                    </tbody>)}
                </table>

                <table className="pure-table pure-table-bordered" style={{ float: 'left', margin: 10 }}>
                    <thead>
                        <tr>
                            <th scope="col">Intervention</th>
                            <th scope="col">Stk verkauft</th>
                            <th scope="col">$</th>
                        </tr>
                    </thead>
                    {Object.keys(balance).length !== 0 && (<tbody>
                        {Object.keys(balance.intervention_results).map((k, i) => {
                            let data = balance.intervention_results[k];
                            return (
                                <tr key={i}>
                                    <td>{k}</td>
                                    <td>{data.totalsold}</td>
                                    <td>{data.totalmoney.toFixed(2)}</td>
                                </tr>
                            )
                        })}

                    </tbody>)}
                </table>

            </div>
        )

        const article_balance_table = (
            <div>
                <table className="pure-table pure-table-bordered" style={{ float: 'left', margin: 10 }}>
                    <thead>
                        <tr>
                            <th scope="col">wer</th>
                            <th scope="col">Stk verkauft</th>
                            <th scope="col">$</th>
                        </tr>
                    </thead>
                    {Object.keys(balance).length !== 0 && (<tbody>
                        {Object.keys(balance.personal_results).map((k, i) => {
                            let data = balance.personal_results[k];
                            return (
                                <tr key={i}>
                                    <td>{k}</td>
                                    <td>{data.totalsold}</td>
                                    <td>{data.totalmoney.toFixed(2)}</td>
                                </tr>
                            )
                        })}

                    </tbody>)}
                </table>

                <table className="pure-table pure-table-bordered" style={{ float: 'left', margin: 10 }}>
                    <thead>
                        <tr>
                            <th scope="col">Artikel</th>
                            <th scope="col">Stk verkauft</th>
                            <th scope="col">$</th>
                        </tr>
                    </thead>
                    {Object.keys(balance).length !== 0 && (<tbody>
                        {Object.keys(balance.article_results).map((k, i) => {
                            let data = balance.article_results[k];
                            return (
                                <tr key={i}>
                                    <td>{k}</td>
                                    <td>{data.totalsold}</td>
                                    <td>{data.totalmoney.toFixed(2)}</td>
                                </tr>
                            )
                        })}

                    </tbody>)}
                </table>
            </div>
        )

        return (
            <div>
                {heading}
                <div><b style={{color:"#bb001d"}}>wenn man nix auswählt, ist es so als wäre alles ausgewählt!</b></div>
                {ogs.length === 0 && articles.length === 0 && loading}
                {form}
                {Object.keys(balance).length !== 0 && og_balance_table}
                {Object.keys(balance).length !== 0 && user_balance_table}
                {Object.keys(balance).length !== 0 && article_balance_table}
            </div>
        )
    }
}

export default (Balance);