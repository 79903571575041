import axios from 'axios';
import { parseJwt } from './functions'

axios.defaults.baseURL="https://kiwy.at/api";
//axios.defaults.baseURL="http://localhost:5000";

export const Endpoints = {
    USER: "/user",
    OG: "/og",
    ACCOUNT: "/account",
    ARTICLE: "/article",
    INTERVENTION: "/intervention",
    POLWORKAREA: "/polworkarea",
    TECWORKAREA: "/tecworkarea",
    SALE: "/sale",
    ARTICLECATEGORY: "/articlecategory",
    SETTLEDCASHBALANCE: "/balance/settled",
    WAREHOUSETRANSACTION: "/"
}

export const login = (user) => {
    return axios.post('/login', user);
}

export const fetch = (endpoint) => {
    return axios.get(endpoint);
}

export const save = (endpoint, element) => {
    if (!isNaN(element.id)) {
        return axios.put(endpoint + "/" + element.id, element);
    } else {
        return axios.post(endpoint, element);
    }
}

export const fetchUsers = () => {
    return axios.get('/user');
}

export const saveUser = (user) => {
    if (!isNaN(user.id)) {
        return axios.put('/user/' + user.id, user);
    } else {
        return axios.post('/user', user);
    }
}

export const fetchOgs = () => {
    return axios.get('/og');
}

export const fetchLoggedInUser = () => {
    const token = localStorage.getItem('token')

    if (parseJwt(token).identity) {
        return axios.get('/user/' + parseJwt(token).identity.id);
    }
    
    return {};
}

export const updatePassword = (user, req) => {
    return axios.put('/user/' + user.id + '/password', req);
}

export const fetchOgFormdata = () => {
    return axios.get('/og/form');
}

export const fetchAccounts = () => {
    return axios.get('/account');
}

export const saveOg = (og) => {
    if (!isNaN(og.id)) {
        return axios.put('/og/' + og.id, og);
    } else {
        return axios.post('/og', og);
    }
}

export const getBalance = (req) => {
    return axios.post('/balance', req);
}

export const getCashBalance = () => {
    return axios.get('/cashbalance');
}

export const postCashBalance = (req) => {
    return axios.post('/cashbalance', req);
}

export const getOpenCashBalance = () => {
    return axios.get('/opencashbalance');
}

export const confirmCashBalance = (req) => {
    return axios.post('/cashbalance/' + req.id);
}

export const getUserCashBalance = (req) => {
    return axios.get('/user/' + req.id + '/balance');
}

export const cancelCashBalance = (req) => {
    return axios.delete('/cashbalance/' + req.id);
}

export const getWarehouseBalance = (req) => {
    return axios.get('/balance/warehouse');
}