import React from 'react';
import { Field } from 'formik';
import MySelect from '../ui/my-select';

export const ArticleForm = ({ values, handleSubmit, handleCancel, formErrors, articleCategories, setFieldValue }) => {
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row">
                <label htmlFor="author" className="col-sm-1 col-form-label">Autor</label>
                <div className="col-sm-5">
                    <Field type="text" name="author" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.author}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="name" className="col-sm-1 col-form-label">Name</label>
                <div className="col-sm-5">
                    <Field type="text" name="name" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.name}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="price" className="col-sm-1 col-form-label">Preis</label>
                <div className="col-sm-5">
                    <Field type="number" name="price" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.price}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="category" className="col-sm-1 col-form-label">Kategorie</label>
                <div className="col-sm-5">
                    <MySelect
                        id="category"
                        name="category"
                        value={values.category}
                        options={articleCategories}
                        onChange={setFieldValue}
                        className="form-control"
                    />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.polworkarea}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="comment" className="col-sm-1 col-form-label">Kommentar</label>
                <div className="col-sm-5">
                    <Field type="textarea" name="comment" className="form-control" />
                </div>
                <div className="col-sm-5">
                    {formErrors.message && <span className="form-error">{formErrors.message.comment}</span>}
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="submit" className="col-sm-1 col-form-label">Submit</label>
                <div className="col-sm-4">
                    <button type="submit" className="btn btn-primary btn-block">submit</button>
                </div>
                <div className="col-sm-1">
                    <button onClick={handleCancel} className="btn btn-block">cancel</button>
                </div>
                <div className="col-sm-5">
                    {typeof formErrors.message === 'string' && <span className="form-error">{formErrors.message}</span>}
                </div>
            </div>
        </form>
    )
}